import React from 'react';
import { Layout } from '../components/Layout';
import { BlogPost } from '../components/BlogPost';
import muiTheme from '../utils/muiTheme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { Contact } from '../components/Contact';
import SEO from '../components/seo';

const BlogPostPage = data => {
  const { html, frontmatter, fields } = data.pageContext.data;

  return (
    <ThemeProvider theme={muiTheme}>
      <Layout isShifted>
        <SEO title={frontmatter.title} image={frontmatter.thumbnail} />
        <BlogPost {...frontmatter} author={fields.author} content={html} />
        <Contact id="contact" isWhite />
      </Layout>
    </ThemeProvider>
  );
};

export default BlogPostPage;
