import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

export const CustomButton = ({
  children,
  onClick,
  title,
  color,
  className,
  borderRadius,
  ...props
}) => {
  const computedClass = classNames(
    borderRadius && `button--${borderRadius}`,
    className
  );

  return (
    <Button
      {...props}
      onClick={onClick}
      className={computedClass}
      variant="contained"
      color={color}
      size="large"
    >
      {title}
    </Button>
  );
};

CustomButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  borderRadius: PropTypes.oneOf('full'),
};

CustomButton.defaultProps = {
  color: 'primary',
};
