import React from 'react';

import './styles.scss';
import { DATE_TIME_FORMAT, formatTimestamp } from '../../utils/dates';

export const PostAuthor = ({ name, avatar, date }) => {
  return (
    <figure className="post-author">
      <img src={avatar} className="post-author__avatar" alt={name}/>
      <figcaption className="post-author__caption">
        <h4 className="post-author__name">{name}</h4>
        <h5 className="post-author__role">
          {formatTimestamp(date, DATE_TIME_FORMAT)}
        </h5>
      </figcaption>
    </figure>
  );
};
