import React from 'react';
import './styles.scss';
import { Facebook, Instagram, LinkedinBox } from 'mdi-material-ui';
import { InstagramWidget } from '../InstagramWidget';
import { Section } from '../Section';

export const Contact = ({ id, isWhite }) => {
  return (
    <Section id={id} isWhite={isWhite}>
      <div className="section__items--flex">
        <div className="contact">
          <h1 className="section__title--primary">Contact</h1>
          <p>
            <span>
              Email us at{' '}
              <a
                href="mailto:contact@girlstocode.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact@girlstocode.com
              </a>
            </span>{' '}
            <br />
            <span>
              <strong>Follow us</strong>
            </span>
            <br />
            <a
              href="https://www.facebook.com/girlstocode/"
              title="facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook fontSize="large" />
            </a>
            <a
              href="https://www.linkedin.com/company/girlstocode/"
              title="linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinBox fontSize="large" />
            </a>
            <a
              href="https://www.instagram.com/girlstocode/"
              title="instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram fontSize="large" />
            </a>
          </p>
          <p>
            <strong>Luxdone</strong> <br />
            Westerplatte 3/6
            <br />
            31-033 Cracow <br />
            Poland <br />
          </p>
        </div>
        <InstagramWidget />
      </div>
    </Section>
  );
};

Contact.defaultProps = {
  isWhite: false,
};
