import React from 'react';
import './styles.scss';
import { Instagram } from 'mdi-material-ui';

export const InstagramWidget = () => {
  return (
    <div className="instagram-widget">
      <a
        className="instagram-widget__link"
        href="https://www.instagram.com/girlstocode/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
      </a>
      <div className="instagram-widget__title">
        <span>@girlstocode</span>
        <Instagram />
      </div>
      <iframe
        title="instagram-widget"
        src="https://snapwidget.com/embed/784145"
        className="snapwidget-widget"
        scrolling="no"
      />
    </div>
  );
};
