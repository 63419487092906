import React from 'react';
import './styles.scss';
import { Section } from '../Section';
import { PostThumbnail } from '../PostThumbnail';
import { CustomButton } from '../Button';
import { PostAuthor } from '../PostAuthor';

export const BlogPost = ({ title, date, content, thumbnail, author }) => {
  const redirectToBlog = () => (window.location = '/blog');

  return (
    <Section id="post" isFullMobileWidth>
      <article className="post-wrapper">
        <PostThumbnail image={thumbnail} size="big" />
        <div className="post-wrapper__content">
          <h1 className="post__title">{title}</h1>
          <PostAuthor {...author} date={date} />
          <hr className="post-wrapper__spacer" />
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </article>
      <CustomButton
        title="See more posts"
        onClick={() => redirectToBlog()}
        borderRadius="full"
        className="post__button"
      />
    </Section>
  );
};
